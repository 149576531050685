// import Footer from "./components/Footer";
import Routes from "./routes";
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';



function App() {
  return (
    <>
      
      <Routes />
      {/* <Footer /> */}
      <ToastContainer />
    </>
  );
}

export default App;
