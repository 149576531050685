import styled from "styled-components";
import { Pngs } from "../../assets";

export const PageWrapper = styled.div`
  /* background-image: conic-gradient(
      from 0deg at 20% 0%,
      rgba(0, 0, 0, 0) 0deg,
      #000000 1.15deg,
      rgba(0, 0, 0, 0) 360deg
    ),
    url(${Pngs.BG_IMAGE});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  background: #000000;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: scroll;

  .message {
    position: absolute;
    width: 100%;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    margin-top: 180px;
    margin-left: 25px;
    color: #ffffff;
    text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.55);
  }
`;

export const PageTitle = styled.p`
  font-family: "Cinzel Decorative";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  /* or 150% */

  text-align: center;
  letter-spacing: 0.02em;

  color: #ffffff;
  padding-top: 150px;

  text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.55);
  @media screen and (max-width: 1197px) {
    padding-top: 140px;
    font-size: 22px;
  }
`;

export const PageSubTitle = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 0.02em;

  color: #ffffff;
  /* margin-top: 16px; */
  margin-bottom: 56px;
  text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.55);
  @media screen and (max-width: 1197px) {
    /* padding-top: 40px; */
    font-size: 16px;
    margin-top: 6px;
    margin-bottom: 16px;
  }
`;

export const PageButton = styled.button`
  border: none;
  background: #d81159;
  box-shadow: 0px 4px 12px rgba(255, 255, 255, 0.12);
  border-radius: 6px;
  border-radius: 6px;
  padding: 13px 29px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  cursor: pointer;
  width: 15%;
  margin-top: 32px;
  @media screen and (max-width: 1197px) {
    width: 45%;
    font-size: 14px;
    padding: 13px 12px;
  }
`;

export const PageSectionTitle = styled.p`
  font-family: "Poppins";
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;

  color: #ffffff;

  @media screen and (max-width: 1197px) {
    margin-top: 40px;
  }
`;

export const InputSectionWrapper = styled.div`
  display: grid;
  /* grid-template-columns: auto auto; */
  /* gap: 50px; */
  margin-top: 25px;
  margin-bottom: 40px;
`;

export const Input = styled.input`
  background: #000000;
  border-radius: 5px;
  border: none;
  width: 104px;
  height: 104px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  /* line-height: 72px; */
  border: 6px solid #282727;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  outline: 1px solid linear-gradient(91.68deg, #8337eb 2.2%, #b84df3 97.23%);
  @media screen and (max-width: 1197px) {
    width: 94px;
    height: 94px;
    font-size: 38px;
  }
`;

export const MintQuantity = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  /* margin-top: 64px; */
  margin-bottom: 19px;
  text-align: center;

  color: #ffffff;
`;

export const WalletWrapper = styled.div`
  /* position: absolute; */
  /* bottom: 11px;
  left: 0;
  right: 0; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 64px;

  @media screen and (max-width: 1197px) {
    position: relative;
    bottom: unset;
    top: unset;
    left: unset;
    right: unset;
    /* width: 45%;
    font-size: 14px;
    padding: 13px 12px; */
  }
`;

export const Wallet = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */
  margin-bottom: 4px;
  text-align: center;
  text-transform: capitalize;

  color: #ffffff;
  @media screen and (max-width: 1197px) {
    margin-top: 40px;
  }
`;

export const WalletAddressContainer = styled.div`
  background: #262525;
  border: 3px solid #212121;
  border-radius: 3px;
  padding: 14px 21px;
`;

export const WalletAddress = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  .wallet-address {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
  }
`;

export const ErrorMessage = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  line-height: 32px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.02em;

  color: #ff0000;

  text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.55);
  margin-top: 24px;
  color: #ff0000;
  /* margin-top: 24px; */
  /* margin-bottom: 48px; */
  text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.55);
  @media screen and (max-width: 1197px) {
    font-size: 14px;
  }
`;

export const PageButtonTransparent = styled.button`
  /* border: none; */
  /* background: linear-gradient(91.68deg, #8337eb 2.2%, #b84df3 97.23%); */
  /* box-shadow: 0px 4px 12px rgba(255, 255, 255, 0.12); */
  /* border-radius: 6px; */
  border: 1px solid #ffffff;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 12px rgba(255, 255, 255, 0.12));
  border-radius: 6px;
  padding: 13px 29px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  background: transparent;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  cursor: pointer;
  width: 15%;
  margin-top: 24px;
  @media screen and (max-width: 1197px) {
    width: 45%;
    font-size: 14px;
    padding: 13px 12px;
  }
`;

export const MetamaskDownload = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  background: #2e2e2e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 16px;

  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: #ffffff;
    margin-top: 20px;
    text-align: center;
    text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.55);
  }

  .chrome-message {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  a {
    text-decoration: underline;
    color: #ffffff;
  }
`;

export const Desktop = styled.div`
  /* display: flex;
  flex-direction: column; */
  width: 100%;
  @media screen and (max-width: 1194px) {
    display: none;
  }
`;
