import { Modal } from "react-bootstrap";
import { Svgs } from "../../assets";
import {
  CancleBtn,
  ModalIconsContainer,
  ModalMessageconsContainer,
  ModalSubText,
  ModalTitle,
} from "./styles";

export const SmcModal = (props) => (
  <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    // show={props.show}
  >
    <CancleBtn onClick={props.onHide} />
    <Modal.Body className="smc-modal-body">
      <Svgs.CHECK_MARK />
      <ModalTitle>SUCCESS! YOUR MINT IS COMPLETE</ModalTitle>
      <ModalSubText>Share it with your friends</ModalSubText>
      <ModalMessageconsContainer>
        <p>
          I just minted my Sabertooth NFT Go to www.sabertoothpokerclub.com to
          buy your NFT. |
        </p>
      </ModalMessageconsContainer>
      <ModalIconsContainer>
        <Svgs.TWITTER />
        <Svgs.INSTAGRAM />
        <Svgs.ATTACHMENT />
      </ModalIconsContainer>
    </Modal.Body>
  </Modal>
);
