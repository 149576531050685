import React from "react";
import { Pngs, Svgs } from "../../assets";
import { PageButton } from "../Home/styles";
import {
  Divider,
  Mobile,
  MonthContentDot,
  MonthLabel,
  MonthWrapper,
  PageSubTitle,
  PageTitle,
  PageWrapper,
  RightContent,
  Timeline,
} from "./styles";

const MobileIntro = () => {
  const handleOpen = () => {
    window.open('https://metamask.io/download/', '_blank');
  }
  return (
    <PageWrapper>
      <PageTitle>Sabertooth Poker Club</PageTitle>
      <PageSubTitle>Follow the below steps to mint on mobile</PageSubTitle>
      <Timeline>
        <RightContent>
          <Mobile>
            <MonthWrapper>
              <div className="title-row">
                <MonthLabel>Download & Install Metamask app</MonthLabel>
                <MonthContentDot />
              </div>
              {/* <MonthContentWrapper> */}
                <Svgs.METAMASK />
              {/* </MonthContentWrapper> */}
            </MonthWrapper>
            <MonthWrapper>
              <div className="title-row">
                <MonthLabel>Open Metamask Browser</MonthLabel>
                <MonthContentDot />
              </div>
              <img src={Pngs.BROWSER} alt="" />
            </MonthWrapper>
            <MonthWrapper>
              <div className="title-row">
                <MonthLabel>
                  Visit mint.sabertoothpokerclub.com from metamask browser
                </MonthLabel>
                <MonthContentDot />
              </div>
              <img src={Pngs.MINT_PAGE} alt="" />
            </MonthWrapper>
          </Mobile>
        </RightContent>
        <Divider />
      </Timeline>
      <PageButton onClick={handleOpen} style={{width: '90%'}}>Install Metamask</PageButton>
    </PageWrapper>
  );
};

export default MobileIntro;
