import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import { Pngs, Svgs } from "../../assets";
import Loader from "../../components/Loader";
import { SmcModal } from "../../components/Modal";
import { checkWallet, fetchData } from "../../redux/data/dataActions";
// import { checkBrowser } from "../../utils/checkbrowser";
import { isMobile } from "../../utils/isMobile";
import CountDown from "../Countdown";
import MintProcess from "../MintProcess";
import MobileIntro from "../MobileIntro";
import { PageSubTitle } from "../MobileIntro/styles";
import Welcome from "../Welcome";
import {
  ErrorMessage,
  MetamaskDownload,
  PageButton,
  PageButtonTransparent,
  PageTitle,
  PageWrapper,
} from "./styles";

const Home = () => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [modalShow, setModalShow] = useState(false);
  const [extFound, setExtFound] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMessge, setLoadingMessage] = useState("");
  const [walletSignature, setWalletSignature] = useState();
  const [value1, setValue1] = useState(1);
  const [value2, setValue2] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  useEffect(() => {
    if (walletSignature) {
      (async () => {
        await dispatch(checkWallet(walletSignature));
      })();
    }
  }, [dispatch, walletSignature]);

  const value = value1;
  const claimNFTs = () => {
    if (JSON.parse(process.env.REACT_APP_SALE_PAUSED)) return window.location.reload();
    if (value > 0) {
      setLoadingMessage(
        `We are minting your Sabertooth NFTs Do not exit the page`
      );
      let cost = CONFIG.WEI_COST;
      let gasLimit = CONFIG.GAS_LIMIT;
      let totalCostWei = cost * value;
      let totalGasLimit = gasLimit * value;
  
      setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
      setClaimingNft(true);
      blockchain.smartContract.methods
        .mint(value, data?.data?.signature)
        .send({
          gasLimit: String(totalGasLimit),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .once("error", (err) => {
          console.log("err", err);
          setFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log("receipt", receipt);
          setModalShow(true);
          setFeedback(
            `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
          );
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const getData = useCallback(() => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.account, blockchain.smartContract, dispatch]);

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account, getData]);

  useEffect(() => {
    if (window.ethereum) {
      setLoading(true);
      handleEthereum();
    } else {
      setLoading(true);
      window.addEventListener("ethereum#initialized", handleEthereum, {
        once: true,
      });

      // If the event is not dispatched by the end of the timeout,
      // the user probably doesn't have MetaMask installed.
      setTimeout(handleEthereum, 2000); // 3 seconds
    }

    return () => {
      window.removeEventListener("ethereum#initialized", handleEthereum);
      clearTimeout(3000);
    };
  }, []);

  function handleEthereum() {
    const { ethereum } = window;
    if (ethereum && ethereum.isMetaMask) {
      setLoading(false);
      setExtFound("Metamask");
      console.log("Ethereum successfully detected!");
    } else {
      setLoading(false);
      console.log("Please install MetaMask!");
    }
  }

  const reload = () => {
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  const discordLink = () => {
    window.open("https://discord.gg/sabertoothpokerclub", "_blank");
  };
  const ErrorExtras = () => (
    <>
      <PageTitle>Sabertooth Poker Club</PageTitle>
      <ErrorMessage>
        {data?.errorMsg === "User not whitelisted"
          ? `THIS WALLET IS NOT ON THE ${process.env.REACT_APP_SALE_TYPE}`
          : data?.errorMsg}
      </ErrorMessage>
      <PageSubTitle>
        Try again with a different wallet or come back for the Public sale
        tomorrow
      </PageSubTitle>
      <PageButton onClick={reload}>Try Again</PageButton>
      <PageButtonTransparent onClick={discordLink}>
        SMC DISCORD
      </PageButtonTransparent>
    </>
  );

  const ErrorTex = () => {
    if (blockchain.errorMsg) {
      return (
        <>
          <ErrorMessage>{blockchain?.errorMsg}</ErrorMessage>
          <Welcome topStyle={{ paddingTop: 0 }} />
        </>
      );
    }

    if (data.errorMsg) {
      return (
        <>
    
          <ErrorExtras />
        </>
      );
    }
    return null;
  };

  if (loading || blockchain.loading || data.loading) {
    return <Loader message={loadingMessge} size="small" />;
  }

  if (claimingNft) {
    return <Loader message={loadingMessge} size="small" />;
  }

  const BrowserVersion = () => {
    const num = "99.0.4844.51";
    const minversion = num.split(".").join("");
    const { userAgent } = navigator;
    if (userAgent.includes("Chrome/")) {
      const v = userAgent.split("Chrome/")[1].split(" ")[0];
      const currentVersion = v.split(".").join("");
      if (Number(currentVersion) < Number(minversion)) {
        return (
          <MetamaskDownload>
            <div className="chrome-message">
              <Svgs.BULB />
              <p>
                To maximise your security and minimise vulnerability use the
                latest version of Chrome.{" "}
                <a href="https://www.google.com/chrome/">
                  Click here to download
                </a>
              </p>
              <img src={Pngs.CHROME} alt="" />
            </div>
          </MetamaskDownload>
        );
      }
      return null;
    }

    return (
      <MetamaskDownload>
        <div className="chrome-message">
          <Svgs.BULB />
          <p>
            To maximise your security and minimise vulnerability we highly
            recommend the latest version of Chrome.{" "}
            <a href="https://www.google.com/chrome/">Click here to download</a>
          </p>
          <img src={Pngs.CHROME} alt="" />
        </div>
      </MetamaskDownload>
    );
  };

  if (!extFound && isMobile()) {
    return <MobileIntro />;
  }

  return (
    <CountDown saleType={process.env.REACT_APP_SALE_TYPE}>
      <>
        <SmcModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          text={feedback}
        />
        <PageWrapper>
          {!blockchain.errorMsg &&
          !blockchain.errorMsg &&
          (blockchain.account === "" || blockchain.smartContract === null) ? (
            <Welcome
              
              saleType={process.env.REACT_APP_SALE_TYPE}
              setWalletSignature={setWalletSignature}
              getData={getData}
              setLoadingMessage={setLoadingMessage}
            />
          ) : null}

          {(blockchain.errorMsg || data.errorMsg) && <ErrorTex />}
          {(!blockchain.loading || !data.loading) &&
            !blockchain.errorMsg &&
            data?.data?.signature && (
              <MintProcess
                saleType={process.env.REACT_APP_SALE_TYPE}
                value={value}
                value1={value1}
                value2={value2}
                setValue1={setValue1}
                setValue2={setValue2}
                claimNFTs={claimNFTs}
                claimingNft={claimingNft}
                getData={getData}
                weiCost={CONFIG.WEI_COST}
                contractAddress={CONFIG.CONTRACT_ADDRESS}
                wallet={blockchain?.account}
              />
            )}
          {!isMobile() && <BrowserVersion />}
        </PageWrapper>
      </>
    </CountDown>
  );
};

export default Home;
