import React from "react";
import { convertWeiToEther } from "../../utils/convertWeiToEther";
import { truncate } from "../../utils/truncate";
import {
  Desktop,
  Input,
  InputSectionWrapper,
  MintQuantity,
  PageButton,
  PageSectionTitle,
  WalletAddress,
  WalletAddressContainer,
  WalletWrapper,
} from "../Home/styles";
import NFTBenefit from "../NFTBenefit";

const MintProcess = ({
  value1,
  value2,
  setValue1,
  setValue2,
  value,
  claimingNft,
  claimNFTs,
  getData,
  weiCost,
  contractAddress,
  wallet
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    if (value1 > 0) {
      claimNFTs();
      getData();
    }
  };
  return (
    <>
      <Desktop>
        <WalletWrapper>
          <WalletAddressContainer>
            <WalletAddress>
              {" "}
              <span className="wallet-address">Wallet Address:</span> {truncate(wallet, 15)}
            </WalletAddress>
          </WalletAddressContainer>
        </WalletWrapper>
      </Desktop>
      <PageSectionTitle>
        How many Sabertooth do you want to mint?
      </PageSectionTitle>
      <InputSectionWrapper>
        <Input
          placeholder="0"
          autoFocus={false}
          onChange={({ target }) => setValue1(target.value)}
          type="text"
          maxLength={3}
          value={value1}
        />
      </InputSectionWrapper>
      <MintQuantity>
        Price: {convertWeiToEther(weiCost) * parseInt(value, 10)} ETH
      </MintQuantity>
      <PageButton disabled={claimingNft ? 1 : 0} onClick={handleClick}>
        {claimingNft ? "MINTING...." : "MINT"}
      </PageButton>
      <NFTBenefit />
    </>
  );
};

export default MintProcess;
