import styled from "styled-components";
import { Pngs } from "../../assets";


export const PageWrapper = styled.div`
  /* background-image: url(${Pngs.COUNTDOWN}); */
  background-image: linear-gradient(#000000, rgba(0, 0, 0, 0)),
    url(${Pngs.COUNTDOWN});
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* align-items: center; */
  position: relative;
  padding: 134px 156px;
  @media screen and (max-width: 1317px) {
  padding: 34px 16px;
  }
`;

export const PageTitle = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 77px;
  /* or 148% */

  color: #ffffff;
  margin-bottom: 56px;
  text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.55);
  @media screen and (max-width: 1317px) {
    font-size: 36px;
    text-align: center;
    line-height: 40px;
  }
`;

export const TimeWrapper = styled.div`
  width: 151px;
  border-radius: 10px;
  @media screen and (max-width: 1317px) {
    width: 100px;
  }
`;

export const TimeContentWrapper = styled.div`
  background: #1c1a1b;
  padding: 20px 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const TimeText = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 130px;
  line-height: 117px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  @media screen and (max-width: 1317px) {
    font-size: 50px;
  }
`;

export const TimeTextWrapper = styled.div`
  @media screen and (max-width: 1317px) {
    /* font-size: 50px; */
  }
`;

export const TimeTextLabel = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  @media screen and (max-width: 1317px) {
    /* font-size: 50px; */
  }
`;

export const DateFooterWrapper = styled.div`
  background: #0d0d0d;
  padding: 8px 54px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  @media screen and (max-width: 1317px) {
    padding: 8px 24px;
  }
`;

export const TimeLabel = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  color: #474747;
  @media screen and (max-width: 1317px) {
  }
`;

// export const TimeText = styled.p`
//   font-family: "Poppins";
//   font-style: normal;
//   font-weight: 600;
//   font-size: 16px;
//   line-height: 24px;
//   /* identical to box height */

//   text-align: center;
//   text-transform: uppercase;

//   color: #474747;
//   @media screen and (max-width: 1317px) {

//   }
// `;

export const BottomTabIndicator = styled.div`
  position: absolute;
  /* left: 0; */
  /* right: 0; */
  bottom: 0;
  background: #d81159;
  width: 35px;
  height: 6px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

export const TimeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
  align-items: center;
  width: 70%;
  margin-bottom: 64px;
  margin-top: 55px;
  @media screen and (max-width: 1065px) {
    gap: 20px;
    margin-top: 20px;
    width: 100%;
  }
`;

export const TimeGridColon = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  /* line-height: 84px; */
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
`;

export const TimeGridButtonWrappper = styled.p`
  display: flex;
  gap: 60px;
  align-items: center;
  /* background: red; */
  margin-top: 64px;
  width: 100%;
`;

export const DiscordButton = styled.button`
  border: none;
  background: linear-gradient(91.68deg, #8337eb 2.2%, #b84df3 97.23%);
  box-shadow: 0px 4px 12px rgba(255, 255, 255, 0.12);
  border-radius: 40px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  /* line-height: 24px; */
  cursor: pointer;
  /* width: 100%; */
  text-align: center;
  text-transform: uppercase;
  justify-content: space-between;

  color: #ffffff;
  padding: 13px 30px;
  @media screen and (max-width: 1065px) {
    /* display: flex;
    flex-direction: column; */
    padding: 11px 17px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    /* line-height: 19px; */
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
  }
`;
