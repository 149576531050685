import BG_IMAGE from "../assets/images/pngs/bg-image.png";
import CHROME from "../assets/images/pngs/chrome.png";
import BROWSER from "../assets/images/pngs/browser.png";
import MINT_PAGE from "../assets/images/pngs/mintpage.png";
import COUNTDOWN from '../assets/images/pngs/countdown.png';

import { ReactComponent as CANCEL_BUTTON } from "./images/svgs/cancel.svg";
import { ReactComponent as CHECK_MARK } from "./images/svgs/check-mark.svg";
import { ReactComponent as TWITTER } from "./images/svgs/twitter.svg";
import { ReactComponent as INSTAGRAM } from "./images/svgs/instagram.svg";
import { ReactComponent as ATTACHMENT } from "./images/svgs/attachment.svg";
// import { ReactComponent as CHROME } from "./images/svgs/chrome.svg";
import { ReactComponent as BULB } from "./images/svgs/bulb.svg";
import {ReactComponent as RED_CHECK} from './images/svgs/red-check.svg';
import {ReactComponent as GREY_LINE} from './images/svgs/grey-line.svg';
import {ReactComponent as METAMASK} from './images/svgs/metamask.svg';

export const Pngs = {
  BG_IMAGE,
  CHROME,
  BROWSER,
  MINT_PAGE,
  COUNTDOWN
};

export const Svgs = {
  CANCEL_BUTTON,
  CHECK_MARK,
  ATTACHMENT,
  TWITTER,
  INSTAGRAM,
  BULB,
  RED_CHECK,
  GREY_LINE,
  METAMASK
};
