import React from "react";
import { PageWrapper } from "../../pages/Home/styles";
import { LoaderWrapper } from "./styles";

const Loader = ({size, message}) => {
  return (
    <PageWrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: '100%',
          position: "relative",
        }}
      >
        <LoaderWrapper size={size}>
          {Array.from(Array(12).keys()).map((i) => (
            <div key={i} />
          ))}
        </LoaderWrapper>
       {message &&  <p className="message">{message}</p>}
      </div>
    </PageWrapper>
  );
};

export default Loader;
