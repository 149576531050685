import React from "react";
// import { useDispatch } from "react-redux";
// import Web3 from "web3";
// import { connect } from "../../redux/blockchain/blockchainActions";
import { PageSubTitle, PageTitle } from "../Home/styles";

const Welcome = ({
  setWalletSignature,
  getData,
  topStyle,
  setLoadingMessage,
  saleType
}) => {
  // const dispatch = useDispatch();

  // async function signMessage() {
  //   if (JSON.parse(process.env.REACT_APP_SALE_PAUSED)) return window.location.reload();
  //   if (window.ethereum) {
  //     const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
  //     const web3 = new Web3(window.ethereum);
  //     const account = accounts[0];
  //     const message = 'I agree to SMC terms and conditions';
  //     const signature = await web3.eth.personal.sign(message, account);
  //     setWalletSignature(signature);
  //   } else console.log("error");
  // }
  // const handleConnect = async (e) => {
  //   setLoadingMessage("Connecting your wallet");
  //   signMessage();
  //   dispatch(connect());
  //   getData();
  // };
  return (
    <>
      <PageTitle style={topStyle}>Sabertooth Poker Club</PageTitle>
      {/* <PageSubTitle>Welcome to the public mint</PageSubTitle> */}
      <PageSubTitle>Mint has come to an end</PageSubTitle>
      {/* <PageButton onClick={handleConnect}>Connect Wallet</PageButton> */}
    </>
  );
};

export default Welcome;
