import Home from "../pages/Home";





export const publicRoutes = [
  {
    url: '/',
    component: Home,
    exact: true,
  },
];
