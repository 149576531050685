import styled from "styled-components";

export const PageWrapper = styled.div`
  padding: 80px 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #000000;
  height: 100vh;
  overflow: scroll;
  @media screen and (max-width: 1197px) {
    padding: 48px 17px;
  }
`;

export const PageTitle = styled.p`
  /* padding: 80px 180px; */
  position: relative;
  font-family: "Cinzel Decorative";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 36px;
  /* identical to box height, or 200% */

  text-align: center;
  letter-spacing: 0.02em;

  color: #ffffff;

  text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.55);
  @media screen and (max-width: 1197px) {
    /* padding: 48px 17px; */
  }
`;

export const PageSubTitle = styled.p`
  /* padding: 80px 180px; */
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.02em;

  color: #ffffff;

  text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.55);
  @media screen and (max-width: 1197px) {
    /* padding: 48px 17px; */
  }
`;

export const Timeline = styled.div`
  /* display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 60px;
  margin-top: 52px; */
  position: relative;
  /* width: 100%; */

  @media screen and (max-width: 1197px) {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 40px;
  }
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* height: 40%; */
`;

export const Mobile = styled.div`
  display: none;
  @media screen and (max-width: 1194px) {
    display: flex;
    flex-direction: column;
  }
`;

export const MonthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* margin-bottom: 360px; */
  width: 385px;
  .title-row {
    /* z-index: 1px; */
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    position: relative;
  }

  .left-label {
    margin-left: 50px;
  }

  @media screen and (max-width: 1197px) {
    margin-bottom: 30px;
    width: 100%;

    .left-label {
      margin-left: 30px;
    }
  }
`;

export const MonthLabel = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  /* width: 120px; */
  color: #ffffff;

  @media screen and (max-width: 1197px) {
    /* font-size: 22px;
    line-height: 34px; */
  }
`;

export const MonthContentWrapper = styled.div`
  background: #eeeeee;
  box-shadow: 0px 4px 23px rgba(255, 255, 255, 0.06);
  border-radius: 12px;
  padding: 32px 20px;
`;

export const MonthContentDot = styled.div`
  width: 22px;
  height: 22px;
  border: 2.5px solid #b7b7b7;
  background: #ffffff;
  border-radius: 50%;
  margin-right: -75px;
  /* z-index: 100; */
  position: absolute;
  right: 0px;
  @media screen and (max-width: 1197px) {
    margin-left: -35px;
    left: 2px;
    top: 0;
    z-index: 10;
  }
`;

export const MonthContentListItem = styled.li`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 36px;
  /* or 230% */

  color: #1e1e1e;
  @media screen and (max-width: 1197px) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #1e1e1e;
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 100%;
  /* background-color: #B6B6B6; */
  border-left: 2px dashed #b6b6b6;
  @media screen and (max-width: 1197px) {
    position: absolute;
    left: -22px;
  }
`;
