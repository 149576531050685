import styled from "styled-components";

export const PageWrapper = styled.div`
  padding: 80px 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media screen and (max-width: 1197px) {
    padding: 48px 17px;
  }
`;

export const SectionTitle = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  @media screen and (max-width: 1197px) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;

    color: #ffffff;
  }
`;

export const PriceSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin-top: 12px;
  .whitelist {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
  }

  .public-sale {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
  }
  @media screen and (max-width: 1197px) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 5px;
    .whitelist {
      font-size: 14px;
    }

    .public-sale {
      font-size: 14px;
    }
  }
`;

export const SectionDescription = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 32px;
  /* or 213% */

  text-align: center;
  font-feature-settings: "salt" on, "liga" off;

  color: #ffffff;
  /* margin-top: 8px; */
  /* width: 37%; */
  @media screen and (max-width: 1197px) {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    width: 80%;
  }
`;

export const NftGridWrapper = styled.div`
  /* width: 100%; */
  border-radius: 16px;
  overflow-x: scroll;
`;
export const NftGridHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); */
  gap: 42px;
  margin-top: 44px;
  background: #1b1b1b;
  padding: 18px;
  /* border-radius: 16px; */
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  /* width: 100%; */
  .content {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 36px;
    color: #ffffff;
    text-align: center;
  }
  @media screen and (max-width: 1197px) {
    /* grid-template-columns: repeat(1, 1fr); */
    gap: 10px;
    /* margin-top: 104px; */
    .content {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: unset;
      color: #ffffff;
    }
  }
`;

export const NftGridBody = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); */
  gap: 82px;
  background: #252525;
  box-shadow: 0px 16px 44px rgba(56, 70, 254, 0.1);
  padding: 18px;
  /* border-radius: 16px; */
  /* width: 100%; */
  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  .content {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 36px;
    color: #ffffff;
    text-align: center;
  }
  .first-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .last-item {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  @media screen and (max-width: 1197px) {
    /* grid-template-columns: repeat(1, 1fr); */
    gap: 10px;
    /* margin-top: 104px; */
  }
`;

export const GridContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #171717;
  box-shadow: 0px 4px 23px rgba(255, 255, 255, 0.06);
  border-radius: 16px;
  padding: 48px 36px 48px;
  position: relative;

  .disabled {
    cursor: no-drop;

    background: #4b4b4b;
    box-shadow: 0px 4px 12px rgba(255, 255, 255, 0.12);
    border-radius: 6px;
  }
`;

export const GridTitle = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height */

  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: #ffffff;
  padding-top: 50px;
  text-align: center;
`;

export const Description = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  /* or 144% */

  text-align: center;

  color: rgba(255, 255, 255, 0.8);
  padding-top: 16px;
`;

export const IconBackground = styled.div`
  background: #171717;
  border: 1px solid #686868;
  box-sizing: border-box;
  box-shadow: 0px 4px 23px rgba(255, 255, 255, 0.06);
  border-radius: 10px;
  padding: 25px;
  position: absolute;
  top: -50px;
`;
export const Button = styled.button`
  border: none;
  background: linear-gradient(91.68deg, #8337eb 2.2%, #b84df3 97.23%);
  box-shadow: 0px 4px 12px rgba(255, 255, 255, 0.12);
  border-radius: 6px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 53px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  margin-top: 32px;
`;
