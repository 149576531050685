import React from "react";
import Countdown from 'react-countdown';
import {
  DiscordButton,
  PageTitle,
  PageWrapper,
  // TimeGrid,
  // TimeText,
  // TimeTextLabel,
  // TimeTextWrapper,
} from "./styles";

const CountDown = ({children, saleType, OGTime, WLTime, PBTime, waitTime}) => {
  const handleNav = () => {
    window.open("https://opensea.io/collection/sabertoothpokerclub", "_blank");
  };
  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <PageWrapper>
        <>
          <PageTitle>SABERTOOTH POKER CLUB</PageTitle>
          <PageTitle>MINT IS OVER</PageTitle>
          {/* <TimeGrid>
            <TimeTextWrapper>
              <TimeText>{days}</TimeText>
              <TimeTextLabel>Days</TimeTextLabel>
            </TimeTextWrapper>
            <TimeTextWrapper>
              <TimeText>{hours}</TimeText>
              <TimeTextLabel>Hours</TimeTextLabel>
            </TimeTextWrapper>
            <TimeTextWrapper>
              <TimeText>{minutes}</TimeText>
              <TimeTextLabel>MINS</TimeTextLabel>
            </TimeTextWrapper>
            <TimeTextWrapper>
              <TimeText>{seconds}</TimeText>
              <TimeTextLabel>SECS</TimeTextLabel>
            </TimeTextWrapper>
          </TimeGrid> */}
          <DiscordButton onClick={handleNav}>Opensea</DiscordButton>
        </>
        </PageWrapper>
      );
    } else {
      return null;
    }
  };

  return (
      <>
      <Countdown date={process.env.REACT_APP_WAIT_TIME} renderer={renderer} />
      </>
  );
};

export default CountDown;
