import styled from "styled-components";
import { Svgs } from "../../assets";

export const ModalTitle = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.02em;
  margin-top: 32px;
  color: #000000;
`;

export const ModalSubText = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.02em;

  color: #9f9f9f;
`;

export const CancleBtn = styled(Svgs.CANCEL_BUTTON)`
  align-self: flex-end;
  margin-bottom: 40px;
  cursor: pointer;
`;

export const ModalIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
`;

export const ModalMessageconsContainer = styled.div`
  background: #f2f2f2;
  border-radius: 4px;
  padding: 13px 10px;
  /* width: 100%; */

  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 23px;
    /* or 192% */

    color: #393939;
  }
`;