import React from "react";
import { Svgs } from "../../assets";
import {
  NftGridBody,
  NftGridHeader,
  NftGridWrapper,
  PageWrapper,
  SectionDescription,
  SectionTitle,
} from "./styles";

const data = [
  {
    id: 1,
    nfts: 1,
    carnft: false,
    condonft: false,
    dragonnft: false,
    landnft: false,
  },
  {
    id: 2,
    nfts: 3,
    carnft: true,
    condonft: false,
    dragonnft: false,
    landnft: false,
  },
  {
    id: 3,
    nfts: 5,
    carnft: true,
    condonft: true,
    dragonnft: false,
    landnft: false,
  },
  {
    id: 4,
    nfts: 10,
    carnft: true,
    condonft: true,
    dragonnft: true,
    landnft: false,
  },
  {
    id: 5,
    nfts: 20,
    carnft: true,
    condonft: true,
    dragonnft: true,
    landnft: true,
  },
];

const NFTBenefit = (id) => {
  return (
    <PageWrapper className="">
      <SectionTitle id={id}>HOW MANY NFTs SHOULD I MINT</SectionTitle>
      <SectionDescription>
        3D FBX files will be available upon request.
      </SectionDescription>
      <SectionDescription>
        Free NFTs will be airdropped starting 8-12 weeks post-mint.
      </SectionDescription>
      <SectionDescription>
        NFT owners will be airdropped Chronos coins at the time of our token
        launch.
      </SectionDescription>

      <NftGridWrapper>
        <NftGridHeader>
          <p className="content"># Sabertooth NFTs</p>
          <p className="content">Free Car NFT</p>
          <p className="content">Free Condo NFT</p>
          <p className="content">Free Dragon NFT</p>
          <p className="content">Free Land Parcel NFT</p>
        </NftGridHeader>
        {data?.map((item) => (
          <NftGridBody key={item?.id}>
            <p className="first-item content">
              <Svgs.RED_CHECK /> {item.nfts}
            </p>
            <p className="content">
              {item.carnft ? <Svgs.RED_CHECK /> : <Svgs.GREY_LINE />}
            </p>
            <p className="content">
              {item.condonft ? <Svgs.RED_CHECK /> : <Svgs.GREY_LINE />}
            </p>
            <p className="content">
              {item.dragonnft ? <Svgs.RED_CHECK /> : <Svgs.GREY_LINE />}
            </p>
            <p className="last-item content">
              {item.landnft ? <Svgs.RED_CHECK /> : <Svgs.GREY_LINE />}
            </p>
          </NftGridBody>
        ))}
      </NftGridWrapper>
    </PageWrapper>
  );
};

export default NFTBenefit;
