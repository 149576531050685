import axios from "axios";
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};


export const fetchData = () => async (dispatch) => {
  try {
    dispatch(fetchDataRequest());
    const request = await store
      .getState()
      .blockchain.smartContract.methods.totalSupply()
      .call();
    return dispatch(fetchDataSuccess(request));
  } catch (error) {
    return dispatch(fetchDataFailed(error));
  }
};

const fetchVerifyDataRequest = () => {
  return {
    type: "VERIFY_REQUEST",
  };
};

const fetchVerifyDataSuccess = (payload) => {
  return {
    type: "VERIFY_SUCCESS",
    payload: payload,
  };
};

const fetchVerifyDataFailed = (payload) => {
  return {
    type: "VERIFY_FAILED",
    payload: payload,
  };
};

export const checkWallet = (signature) => async (dispatch) => {
  try {
    dispatch(fetchVerifyDataRequest());
    let res = await axios.get(
      `${process.env.REACT_APP_API_URL}users/${signature}`
    );
    return dispatch(fetchVerifyDataSuccess(res));
  } catch (error) {
    return dispatch(fetchVerifyDataFailed(error?.response?.data?.message));
  }
};
